body.dragging, body.dragging * {
  cursor: move !important;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}

ol.sortable-list li, ul.sortable-list li{
  cursor: move !important;
}

ol.sortable-list li.placeholder, ul.sortable-list li.placeholder {
  position: relative;
  /** More li styles **/
  margin: 0;
  padding: 0;
  border: none;
  list-style-type: none;
}
ol.sortable-list li.placeholder:before, ul.sortable-list li.placeholder:before {
  position: absolute;
  /** Define arrowhead **/
  content: "";
  width: 0;
  height: 0;
  margin-top: -5px;
  left: -5px;
  top: 0;
  border: 5px solid transparent;
  border-left-color: red;
  border-right: none;
}