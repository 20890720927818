@import "bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";
@import "bootstrap-select/dist/css/bootstrap-select.css";
@import "bootstrap-slider/dist/css/bootstrap-slider.min.css";
@import "bootstrap-toggle/css/bootstrap-toggle.min.css";
@import "daterangepicker/daterangepicker.css";
@import "dropzone/dist/dropzone.css";
@import "jquery-ui/dist/themes/base/jquery-ui.min.css";
@import "jquery-ui/dist/themes/base/theme.css";
@import "./jquery-sortable.css";
@import "leaflet/dist/leaflet.css";
@import "animate.css/animate.min.css";
@import "@fullcalendar/common/main.min.css";
@import "@fullcalendar/daygrid/main.min.css";
@import "@fullcalendar/bootstrap/main.css";
@import "./jquery-nestable.css";
@import "./nifty-2.6.min.css";
@import "./nifty-2.9.css";

.bootstrap-select .dropdown-toggle .filter-option-inner-inner:empty {
    display: inline-block;
}

.leaflet-popup-content {
    font-size: 1.5em;
    color: #ababab;

}