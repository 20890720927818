.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    /*line-height: 20px;*/
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd-list .dd-list > .dd-item {
    padding-left: 25px;
}

.dd-collapsed .dd-list {
    display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
}

.dd-handle {
    margin: 3px 0;
    padding: 7px 10px;
    color: #2b425b;
    text-decoration: none;
    font-weight: normal;
}

.dd-handle i {
    vertical-align: middle;
    line-height: 1
}

.dd-handle:hover {
    cursor: grab;
    cursor: -webkit-grab;
}

.dd-item > button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    /*height: 20px;*/
    margin: 5px 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    /*line-height: 1;*/
    text-align: center;
    font-weight: bold;
}

.dd-item > button:before {
    content: '+';
    color: #2b425b;
    font-weight: 700;
    font-size: 1.2em;
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
    content: '-';
}

.dd-placeholder,
.dd-empty {
    margin: 3px 0;
    padding: 0;
    background-color: #fcfcc8;
    border: 1px dashed #a0a4a8;
}

.dd-empty {
    border: 1px dashed #a0a4a8;
    min-height: 100px;
    background-color: #fcfcc8;
}

.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
}

body.dd-dragging,
body.dd-dragging * {
    cursor: -webkit-grabbing !important;
    cursor: grabbing !important;
}

.dd-dragel > .dd-item .dd-handle {
    margin-top: 0;
}

.dd-bg {
    background-color: #eef2f5;
    transition: all .2s
}

.dd-bg:hover {
    background-color: #e5ebf1;
    font-weight: bold;
    transition: all .2s
}

.dd-outline {
    border: 1px solid #e9e9e9;
    transition: all .2s
}

.dd-outline:hover {
    border: 1px solid #42a5f5;
    transition: all .2s
}

.dd-dashed {
    border-bottom: 1px dashed #e9e9e9;
    transition: all .2s
}

.dd-dashed:hover {
    border-bottom: 1px dashed #42a5f5;
    transition: all .2s
}

.changed .dd-anim {
    animation-name: dd-update;
    animation-duration: 1s;
}

.dd-dragel > .dd-item .dd-anim {
    animation-name: dd-dragging;
    animation-duration: .4s;
    animation-fill-mode: forwards;
}

.dd-dragel > .dd-item .dd-handle {
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .15);
    opacity: .9;
}

@keyframes dd-update {
    1% {
        color: #fff;
        background-color: #42a5f5;
    }
    100% {
        background-color: #eef2f5;
    }
}

@keyframes dd-dragging {
    1% {
        background-color: #eef2f5;
        box-shadow: none
    }
    100% {
        background-color: #42a5f5;
        color: #fff;
        box-shadow: 0 5px 5px rgba(0, 0, 0, .15)
    }
}


/**/

.dd-handle-btn {
    height: 30px;
    width: 30px;
    display: block;
    position: absolute;
    top: 0;
    float: left;
    margin: 0;
    vertical-align: middle
}

.dd-handle-btn:before {
    font-size: 12px;
    content: '';
    height: .2em;
    width: .2em;
    background: #959595;
    color: #959595;
    display: block;
    position: absolute;
    top: 8px;
    left: 11px;
    box-shadow: .5em 0, 0 .5em, .5em .5em, 0 1em, .5em 1em;
    /*box-shadow: .5em 0, 1em 0, 0 .5em, .5em .5em, 1em .5em, 0 1em, .5em 1em, 1em 1em;*/
}
.dd-content{
    padding: 5px 0 0 35px;
    min-height: 30px;
    vertical-align: middle
}

.dd-list-handle-btn > .dd-item{
    position: relative;
}
.dd-list-handle-btn .dd-item > button{
    margin-left: 25px
}

/**
* Nestable Extras 42a5f5
*/

.nestable-lists {
    display: block;
    clear: both;
    padding: 30px 0;
    width: 100%;
    border: 0;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
}

#nestable-menu {
    padding: 0;
    margin: 20px 0;
}

#nestable2 .dd-handle {
    color: #fff;
    border: 1px solid #999;
    background: #bbb;
    background: -webkit-linear-gradient(top, #bbb 0%, #999 100%);
    background: -moz-linear-gradient(top, #bbb 0%, #999 100%);
    background: linear-gradient(top, #bbb 0%, #999 100%);
}

#nestable2 .dd-handle:hover {
    background: #bbb;
}

#nestable2 .dd-item > button:before {
    color: #fff;
}

.dd-hover > .dd-handle {
    background: #2ea8e5 !important;
}


/**
* Nestable Draggable Handles
*/

.dd3-content {
    display: block;
    height: 30px;
    margin: 5px 0;
    padding: 5px 10px 5px 40px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #ccc;
    background: #fafafa;
    background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
    background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
    background: linear-gradient(top, #fafafa 0%, #eee 100%);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd3-content:hover {
    color: #2ea8e5;
    background: #fff;
}

.dd-dragel > .dd3-item > .dd3-content {
    margin: 0;
}

.dd3-item > button {
    margin-left: 30px;
}

.dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #aaa;
    background: #ddd;
    background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
    background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
    background: linear-gradient(top, #ddd 0%, #bbb 100%);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.dd3-handle:before {
    content: '≡';
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    width: 100%;
    text-align: center;
    text-indent: 0;
    color: #fff;
    font-size: 20px;
    font-weight: normal;
}

.dd3-handle:hover {
    background: #ddd;
}