/* Remove transition */
#eurid #container {
    -webkit-transition: none;
    transition: none;
}

#eurid .cls-container:not(.error-page) {
    background-color: #455CC5;
}

#eurid.registrarweb .cls-container:not(.error-page),
#eurid.accessweb .cls-container:not(.error-page),
#eurid.enduserweb .cls-container:not(.error-page) {
    background-color: #20325f;
}

#eurid.lisa .cls-container:not(.error-page) {
    background-color: #771d1d;
}

#eurid.weblate_utilities .cls-container:not(.error-page) {
    background-color: #80b343;
}

#eurid.typdyn .cls-container:not(.error-page) {
    background-color: #2d205e;
}

#eurid .error-code {
    /*font-size: 50px;*/
}

.no-permission, .nrp-disabled, .no-permission.disabled,  .nrp-disabled.disabled{
    opacity: 0.5;
    cursor: not-allowed!important;
}

form.no-permission .no-permission, form.no-permission .disabled {
    opacity: initial;
}

form.no-permission button, form.no-permission, button.no-permission {
    cursor: not-allowed;
}

#eurid legend {
    font-size: 1.1em;
}

.light-text {
    opacity: 0.3;
}


/*#page-title + #page-content > .top-actions, .status-tags-subtitle{*/
/*    max-width: 50%;*/
/*}*/

.iframe-top.top-actions {
    float: none!important;
    margin-top: 0 !important;
}


.iframe-top.top-actions.pull-right {
    text-align: right!important;
}

@media (min-width: 768px) {
    .top-actions {
        /* Saves some space at the top of the page by putting the buttons on the right of the title on the same line */
        margin-top: -55px;
    }

    .aside-in .top-actions.pull-right {
        /* to prevent the aside pushing the top actions button into the breadcrumb */
        float: none !important;
        text-align: right;
        margin-top: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 767px){
    .top-actions {
        width: 100%;
        float: none !important;
    }
    .top-actions.pull-right {
        text-align: right;
    }
    .top-actions, .status-tags-subtitle{
        max-width: 100% !important;
    }
}

.eur-amount {
    font-size: 9px;
    font-weight: bold;
}

#eurid .table-borderless, #eurid .table-borderless td, #eurid .table-borderless th, #eurid .table-borderless tr {
    border: none;
}

#eurid .label {
    text-transform: uppercase;
}

.popover .alert {
    margin-bottom: 0;
}

.add-ajax-popover, .add-tooltip {
    cursor: help;
}

.add-ajax-popover.no-permission, .add-tooltip.no-permission {
    cursor: not-allowed;
}
.adval-result {
    border-left: 5px solid #fff !important;
}

#eurid .adval-result-level {
    border-left-color: #e9e9e9 !important;
}

#eurid .adval-result-level1 {
    border-left-color: #89C064 !important;
}

#eurid .adval-result-level2 {
    border-left-color: #89C064 !important;
}

#eurid .adval-result-level3 {
    border-left-color: #89C064 !important;
}

#eurid .adval-result-level4 {
    border-left-color: #E7CF25 !important;
}

#eurid .adval-result-level5,
#eurid .adval-result-level0 {
    border-left-color: #E07272 !important;
}

.adval-text-level1,
.adval-text-level2,
.adval-text-level3 {
    font-weight: bold;
    color: #2A752A;
}

.adval-text-level4 {
    font-weight: bold;
    color: #A89A34;
}

.adval-text-level5,.adval-text-level0 {
    font-weight: bold;
    color: #AD5555;
}

@media (max-width: 992px) and (min-width: 768px) {
    #eurid #container.aside-in #aside-container {
        /*width: 85px;*/
    }
}

@media (max-width: 992px) and (min-width: 768px) {
    #eurid #container.aside-in #content-container {
        padding-right: 85px;
    }
}

#eurid #aside {
    color: #575757;
    border-left: 1px solid rgb(213, 213, 213);
    background-color: transparent;
}

#eurid #aside h5 {
    margin-left: 7px;
}

#eurid #aside h5 a > i,
#eurid #aside h5 span.highlight > i {
    margin-right: 5px;
}

#eurid #aside table, #eurid #aside td {
    border: 0;
}

#eurid #aside table td:first-child {
    padding-left: 15px;
}

#eurid #aside h5 {
    margin-top: 15px;
    margin-bottom: 5px;
}

.ajax-loading {
    margin-right: 7px;
}

#eurid a.highlight:hover {
    text-decoration: underline;
}

.popover-title-content {
    display: none;
}

.table-data tr td:first-child {
    font-weight: bold;
}

#eurid .panel-group.accordion .panel-title a.btn {
    display: inline-block;
    color: #fff;
}

.panel-body-scrollable {
    overflow: auto;
}

.panel .panel-control .bootstrap-select {
    margin-bottom: 0;
}

.provisioning table.calendar td.week {
    width: 2%;
    font-size: 10px;
}

.provisioning table.calendar td.cell {
    position: relative;
    vertical-align: middle;
    height: 50px;
    width: 14%;
}

.provisioning table.calendar td.day {
    background-color: white;
}

.provisioning table.calendar .day-digit {
    position: absolute;
    top: 3px;
    left: 3px;
    font-size: 10px;
}

.provisioning table.calendar td.not-day {
    background-color: #f6f8fa;
}

.provisioning table {
    border-collapse: separate;
}

.provisioning .overview th,
.provisioning .overview td {
    width: 50px;
}

.provisioning .calendar td,
.provisioning .calendar th {
    height: 30px;
    width: 20px;
    text-align: center;
}

.provisioning .overview {
    text-align: center;
}

#registrar-info > li.active > a, #registrar-tab-content .tab-content:first-child, #statistics > li.active > a, #statistics-tab-content .tab-content:first-child {
    background-color: #f4f4f4;
}

#eurid .form-control.bootstrap-select .caret {
    color: #515151;
}

#eurid.night .form-control.bootstrap-select .caret {
    color: #999;
}

#eurid .history-content {
    overflow: auto;
    word-break: break-all;
    word-wrap: break-word;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 0.8em;
}

#eurid .history-content pre {
    font-size: 1em;
    overflow: auto;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

#eurid .table-history .well {
    border-left-style: solid;
    border-left-color: #BBB;
    border-left-width: 3px;
    display: none;
}

#eurid .table-history .well.history-content{
    display: block;
}

#eurid .modal-iframe {
    width: 70%;
}

#eurid .modal-iframe .modal-content {
    background-color: #e7ebee;
}

#eurid .modal-dialog .close {
    font-size: 2em;
    right: 15px;
}

#eurid .modal-dialog .alert .close {
    font-size: 12.5px;
    right: 10px;
    color: #ffffff;
}

.parsley .parsley-error {
    border-color: red;
}

.parsley .long-list {
    max-width: 240px;
    display: block;
}

.parsley .error-tooltip span {
    color: #fff;
    line-height: 20px;
    background: #f87961;
    border-radius: 4px;
    list-style: none;
    margin-left: 0;
    padding: 3px 3px 3px 7px;
    display: inline-block;
    margin-top: 3px;
    font-size: 11px;
    max-width: 100%;
}

.parsley .error-tooltip:not(.parsley-errors-list) span:after {
    content: "";
    position: relative;
    width: 0;
    height: 0;
    margin-left: -13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f87961;
    top: -23px;
    right: 78%;
}

.parsley .error-tooltip span span {
    font-size: 11px;
    margin-left: 7px;
}

.parsley .error-tooltip span.long-error {
    margin-top: 0;
}

.parsley .error-tooltip span.long-error:after {
    display: none;
}

.parsley .tab-pane .error-tooltip:after {
    left: -40%;
}

#eurid .parsley-login .parsley-error {
    border-color: #f87961;
    transition-duration: 0s;
    background-color: #FFD9CE;
}

#eurid .parsley-login .error-tooltip span{
    color: #fff;
    line-height: 20px;
    background: #f87961;
    border-radius: 4px;
    list-style: none;
    margin-left: 0;
    padding: 3px 3px 0 7px;
    padding-bottom: 3px;
    display: inline-block;
    margin-top: 3px;
    font-size: 11px;
    max-width: 100%;
}

.carret-error {
    display: block;
    height: 0;
    margin-top: 7px;
}

.carret-error:after {
    content: "";
    position: relative;
    width: 0;
    height: 0;
    margin-left: -13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f87961;
    top: -20px;
    left: 30px;
    z-index: 999;
    font-size: 0;
}

.login-container .carret-error:after {
    left: 0;
}

#eurid .has-error .form-control {
    border-color: #e33a4b;
}

#eurid .form-control, #eurid .bootstrap-select:not(.open) > .btn.btn-default {
    border-color: #C7C7C7;
}

.remove-btn-container {
    float: right;
    width: 20px;
    margin-right: -30px;
    margin-top: -5px;
}

.remove-btn-container a {
    background-color: #f4f4f4;
    padding: 5px;
    -webkit-border-radius: 0 10px 10px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 10px 10px 0;
    -moz-background-clip: padding;
    border-radius: 0 10px 10px 0;
    background-clip: padding-box;
}

.multiple > .form-row {
    background-color: #f4f4f4;
    padding: 10px;
    margin: 15px 0;
}

.no-display {
    display: none;
}

.bg-env-dev {
    background-color: #986291;
    color: #fff;
}

.bg-env-as {
    background-color: #50c7a7;
    color: #fff;
}

.bg-env-accnew {
    background-color: #e15a00;
    color: #fff;
}

.bg-env-accprd {
    background-color: #2300e1;
    color: #fff;
}

.bg-env-tryout {
    background-color: #dd4ff7;
    color: #fff;
}

.bg-env-staging {
    background-color: #ebaa4b;
    color: #fff;
}

.bg-env-test {
    background-color: #63bfeb;
    color: #fff;
}

.bg-white {
    background-color: white;
}

.icon-white {
    color: #fff;
}

.status-tags-subtitle {
    line-height: 2em;
}

.sub-title {
    font-size: 0.6em;
}

.break-word-simple {
    word-wrap: break-word;
}

.break-word {
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    white-space: pre-line;
}

.break-word-digest{
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    white-space: pre-line;
    word-break: break-word;
}
.fade-out-text {
    margin-bottom: 5px;
    padding-bottom: 5px;
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    content: "";
    position: relative;
    cursor: hand;
}

.fade-out-text:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 100px, white);
}

.fade-out-text-small {
    margin-bottom: 5px;
    padding-bottom: 5px;
    max-height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    content: "";
    position: relative;
    cursor: hand;
}

.fade-out-text-small:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 50px, white);
}

#container .table {
    table-layout: fixed;
}

#clipboard-modal textarea {
    width: 100%;
    height: 200px;
}

.copy-clipboard-modal-trigger {
    cursor: hand;
}

.input-group-addon, .input-group-btn {
    vertical-align: top;
}

.stats-hidden {
    display: none;
}

#eurid .label {
    border-radius: 5px;
}

#eurid .noUi-connect {
    background: #2caed1;
}

@media (min-width: 768px) {
    #eurid .cls-content .cls-content-sm {
        width: 375px;
    }
}

@media (min-width: 1200px) {
    #eurid > #container .brand-icon {
        transition-duration: 0.2s;

    }
}

.avatar {
    cursor: hand;
}

.user-permission-jumper {
    width: 150px !important;
    margin-bottom: 0;
}

/*#omni-search, #availability-search {*/
/*    margin-top: 4px;*/
/*}*/
/*#omni-search .searchbox, #availability-search .searchbox {*/
/*    left: 12px !important;*/
/*}*/
/*.registrarweb #omni-search, .registrarweb #availability-search {*/
/*    margin-top: 9px;*/
/*}*/
/*.registrarweb #omni-search .searchbox, .registrarweb #availability-search .searchbox {*/
/*    left: 25px !important;*/
/*}*/

/*#omni-search div.searchbox {*/
/*    width: 200px;*/
/*}*/

/*#availability-search div.searchbox {*/
/*    width: 250px;*/
/*}*/

/*#omni-search button,*/
/*#availability-search button {*/
/*    color: rgba(255, 255, 255, 0.5);*/
/*}*/

/*#omni-search div.searchbox input,*/
/*#availability-search div.searchbox input {*/
/*    background-color: rgba(0, 0, 0, 0.1);*/
/*    color: rgba(255, 255, 255, 0.9);*/
/*    border: 0;*/
/*}*/

/*#omni-search div.searchbox input::-webkit-input-placeholder,*/
/*#availability-search div.searchbox input::-webkit-input-placeholder {*/
/*    !* WebKit, Blink, Edge *!*/
/*    color: rgba(255, 255, 255, 0.5);*/
/*}*/

/*#omni-search div.searchbox input::-moz-placeholder,*/
/*#availability-search div.searchbox input::-moz-placeholder {*/
/*    !* Mozilla Firefox 4 to 18 *!*/
/*    color: rgba(255, 255, 255, 0.5);*/
/*}*/

/*#omni-search div.searchbox input::-moz-placeholder,*/
/*#availability-search div.searchbox input::-moz-placeholder {*/
/*    !* Mozilla Firefox 19+ *!*/
/*    color: rgba(255, 255, 255, 0.5);*/
/*}*/

/*#omni-search div.searchbox input::-ms-input-placeholder,*/
/*#availability-search div.searchbox input::-ms-input-placeholder {*/
/*    !* Internet Explorer 10-11 *!*/
/*    color: rgba(255, 255, 255, 0.5);*/
/*}*/

#action-form-registrar .input-group-btn {
    width: inherit;
}

#permission-table .text-danger {
    opacity: 0.2;
}

#permission-table .table-hover {
    background-color: #e3e8ee !important;
    border: 1px solid black;
}

#permission-table th, #permission-table td {
    text-align: center;
    width: 20px;
}

#permission-table td {
    vertical-align: middle;
}

.table-header-rotated {
    border-collapse: collapse;
}

.table-header-rotated td {
    width: 30px;
}

.table-header-rotated td {
    text-align: center;
}

.table-header-rotated th.rotate {
    height: 220px;
    white-space: nowrap;
}

.table-header-rotated th.rotate > div {
    -webkit-transform: rotate(290deg);
    transform: rotate(290deg);
    transform-origin: 0 50%;
    width: 0;
    display: inline-block;
}

.table-header-rotated th.rotate > div > a {
}

.ui-autocomplete-category {
    font-weight: bold;
    padding: 5px 10px 5px 7px;
    line-height: 1.5;
}

.ui-autocomplete-category i.fa-solid,
.ui-autocomplete-category i.fa-regular,
.ui-autocomplete-category i.fa-light,
.ui-autocomplete-category i.fa-thin,
.ui-autocomplete-category i.fa-duotone,
.ui-autocomplete-category i.fa-brands {
    margin-right: 5px;
}

.ui-autocomplete {
    cursor: hand;
}

#eurid fieldset legend {
    padding-left: 0;
}

.text-white {
    color: #fff;
}

#qrcode img {
    margin: 0 auto;
}

.hide-help-text .help-block {
    display: none;
}

#eurid .container-single-page #navbar-container, #eurid .container-single-page .navbar-content {
    background-color: #32404e;
}

#eurid .small-line-chart {
    height: 100px;
}

#eurid .extension-dropdown {
    min-width: 107px;
    width: 107px;
}

#eurid .field-with-extension .dropdown-toggle > .dropdown-caret {
    padding: 0;
}

#eurid .extension-dropdown i {
    width: 24px;
    text-align: center;
}

#eurid .panel-overlay {
    position: relative;
}

#eurid .panel-overlay-content {
    vertical-align: top;
}

#eurid .panel-overlay-icon {
    position: relative;
    /*top: 50%;
    left: 50%;*/
}

#eurid .panel-body {
    transition: min-height 8s;
}

.flip-icon {
    cursor: pointer;
}

.csv-icon {
    margin-top: 17px;
    margin-right: 7px;
    font-size: 1.5em;
    cursor: pointer;
}

.reveal, .revealed {
    transition: text-shadow 0.5s ease, color 0.5s ease;
    cursor: pointer !important;
}

.reveal {
    color: transparent;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

#breadcrumb li a,
#breadcrumb li {
    font-size: 1.2em
}

.has-zoom .highcharts-series-group {
    cursor: ew-resize;
}

.example-enter {
    opacity: 0.01;
    transition: opacity .5s ease-in;
}

.example-enter.example-enter-active {
    opacity: 1;
}

.example-leave {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.example-leave.example-leave-active {
    opacity: 0;
}

.highcharts-container {
    /*  position: inherit !important;*/
}

.highcharts-tooltip {
    z-index: 9998;
}

.badge-gold, .bg-gold {
    background-color: #D9A441;
}

.badge-silver, .bg-silver {
    background-color: #CCC2C2;
}

.badge-bronze, .bg-bronze {
    background-color: #965A38;
}

.capitalize:first-letter {
    text-transform: uppercase;
}

tr.command-success td {
    background-color: #dff0d8;
}

tr.command-failure td {
    background-color: #f2dede;
}

.input-list-widget .list-group {
    border: 1px solid #ddd;
    margin-bottom: 0;
}

.fa-stack-xs {font-size:.6em}
.fa-stack-sm {font-size:.8em}

#recaptcha_response_field {
    margin-top: 1px;
}

#recaptcha_challenge_image {
    width: 298px!important;
}

#eurid #mainnav-menu-wrap {
  margin-top: 0;
  padding-top: 0;
}

#notification-profile-th-border{
    border-top:hidden;
}

tr.notification-profile-th-border{
    border-top:hidden;
}


/*trying to redo the bootstrap btn btn-default somehow for select*/
.monthselect {
    background-color: #fff;
    border-color: #cdd6e1;
    color: #515151;
    font-size: 12px;
    font-weight: 400;
}

.yearselect {
    background-color: #fff;
    border-color: #cdd6e1;
    color: #515151;
    font-size: 12px;
    font-weight: 400;
}

.hourselect {
    background-color: #fff;
    border-color: #cdd6e1;
    color: #515151;
    font-size: 12px;
    font-weight: 400;
}

.minuteselect {
    background-color: #fff;
    border-color: #cdd6e1;
    color: #515151;
    font-size: 12px;
    font-weight: 400;
}

#eurid .selpicker button {
    border-color: #C7C7C7;
}

#eurid .login-container .cls-content .cls-content-sm
{
    background-color: white;
}

#eurid .cls-container:not(.error-page) a {
    color: white;
}

#eurid .cls-container:not(.error-page) .dropdown-menu > li > a {
    color: #758697;
}
#eurid .cls-container:not(.error-page) .dropdown-menu > .active > a,
#eurid .cls-container:not(.error-page) .dropdown-menu > .active > a:focus,
#eurid .cls-container:not(.error-page) .dropdown-menu > .active > a:hover,
#eurid .cls-container:not(.error-page) .dropdown-menu > li > a:hover {
    color: white;
}

#eurid .datepicker table tr td span {
    height: 30px;
    line-height: 30px;
}

#eurid .datepicker .month.disabled {
    color: #ccc;
}

#eurid .domain-label {
    line-height: 2.1em;
}


/**** NIFTY 2.2 to 2.6 ****/

.navbar-top-links > li > a i.fa-solid,
.navbar-top-links > li > a i.fa-regular,
.navbar-top-links > li > a i.fa-light,
.navbar-top-links > li > a i.fa-thin,
.navbar-top-links > li > a i.fa-duotone,
.navbar-top-links > li > a i.fa-brands,
.navbar-top-links > li > a:hover i.fa-solid,
.navbar-top-links > li > a:hover i.fa-regular,
.navbar-top-links > li > a:hover i.fa-light,
.navbar-top-links > li > a:hover i.fa-thin,
.navbar-top-links > li > a:hover i.fa-duotone,
.navbar-top-links > li > a:hover i.fa-brands {
    color: inherit;
}

.text-muted {
    color: #888;
}

#footer {
    height: auto;
    padding: 10px 0;
}

#container .table .command-success td {
    border-top: 1px solid rgba(0,0,0,0.05);
}

#eurid #aside {
    background-color: #f4f4f4;
}

/* form in panel add margin bottom */
.panel > form {
    margin-bottom: 0;
}

.panel-control .btn {
    padding-left: 11px;
    padding-right: 11px;
}

#container:not(.mainnav-sm) #mainnav-menu-wrap > .nano > .nano-content {
    padding-top: 0;
}

/* fix search box margin right */
@media(min-width: 780px) {
    .searchbox {
        margin-right: 0;
        left: 25px;
        position: relative;
    }
}

/* styling for error pages */


.error-page a {
    text-decoration: underline;
    transition: color 0.5s ease-in;
}

.error-page a:hover, .error-page a:active {
    color: #0391d1 !important
}

/* make tabs smaller to avoid tabs wrapping */

.nav-tabs > li > a {
    padding: 10px 6px;
    font-size: 11px;
}

/* aside */

#container.aside-in:not(.aside-float) #footer {
    padding-right: 275px;
}

@media screen and (max-width: 992px) {
    #container.aside-in:not(.aside-float) #footer {
        padding-right: 95px;
    }
}
@media (min-width: 992px) {
    #container.aside-in:not(.aside-float) #content-container {
        padding-right: 255px;
    }
}
@media screen and (max-width: 768px) {
    #container.aside-in:not(.aside-float) #footer {
        padding-right: 0;
    }
    #aside span {
        display: inherit !important;
    }
    #container>div>#aside-container {
        top: 0 !important;
    }

    #container>div>#aside-container>#aside {
        padding-top: 0;
    }
}

@media (width: 992px){
    #aside span.visible-md-inline, #aside table {
        display: none !important;
    }
}

@media screen and (max-height: 800px){
    .scrollable-menu{
        max-height: 85vh;
        height: auto;
        overflow-x: hidden;
    }

}
#navbar li#aside-toggle{
    display: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover
{
    background-color:#42a5f5 !important;
    color: #fff !important;
}

.dropdown-menu li.disabled a {
    color: #bec3c7;
}

.collapse-chevron a {
    cursor: pointer;
}

.visible-lg-table,
.visible-md-table
{
  display: none !important;
}

@media (min-width: 1200px) {
  .visible-lg-table {
    display: table !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-table {
    display: table !important;
  }
}

.panel-heading.long-text {
    height: auto;
}

.panel-heading.long-text .panel-title {
    overflow: unset;
    text-overflow: unset;
    white-space: normal;
}

.panel-heading.long-text .panel-control {
    display: block;
    position: absolute;
    right: 5px;
}

ul.two-columns {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}
@media screen and (max-width: 768px) {
    ul.one-column-xs {
        columns: 1!important;
        -webkit-columns: 1!important;
        -moz-columns: 1!important;
    }
}

#eurid td.mark {
    color: #000;
    background-color: #ffe3a2;
}

/* nifty 2.9 modifications */
#eurid:not(.enduserweb) #content-container:before {
    background-color: transparent !important;
}

#container.cls-container:not(.error-page) .cls-brand .brand-icon, #container.cls-container:not(.error-page) .cls-brand .brand-title {
    color: inherit !important;
}

.input-group-addon {
    font-size: 14px !important;
}

@media screen and (max-width: 767px) {
    .mar-btm-xs {
        margin-bottom: 15px;
    }

    #mainnav-container {
        padding-top: 0;
    }
}

#eurid.enduserweb #content-container {
    padding-bottom: 250px;
}

@media screen and (max-width: 768px) {
    #eurid.enduserweb #content-container {
        padding-bottom: 340px;
    }
}
@media screen and (max-width: 500px){
    #eurid.enduserweb #content-container {
        padding-bottom: 390px;
    }
}

#eurid .bootstrap-select .dropdown-menu.open {
    z-index: 999999 !important;
}

#eurid .btn-primary, #eurid .btn-primary:focus {
    background-color: #294f75 !important;
    border-color: #25476a !important;
    color: #fff;
}

#eurid .btn-primary:hover, #eurid .btn-primary:active {
    background-color: #1c3550 !important;
    border-color: #1c3550 !important;
    color: #fff !important;
}

#eurid .label-primary {
    background-color: #25476a;
    color: #fff;
}

#eurid .bg-night {
    background-color: #38404d;
}

#eurid .badge-primary {
    background-color: #25476a;
    color: #fff;
}

#eurid .modal .panel {
    border: 1px solid #e7ecf3;
    border-bottom: 1px solid rgba(0,0,0,.17);
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    margin-bottom: 20px;
}

/* for dateranges in modals */
#eurid .daterangepicker.dropdown-menu {
    z-index: 10000;
}

@media screen and (max-width: 767px){
    .navbar-top-links .tgl-menu-btn {
            position: static;
    }
}

#eurid #mainnav li .arrow {
    position: relative;
    width: 42px;
    right: -18px;
    text-align: center;
    height: 40px;
    margin-top: -12px;
    padding-top: 12px;
}

#eurid #mainnav-menu a {
    /*white-space: nowrap;*/
}

#eurid #container.mainnav-lg #mainnav-menu i:not(.arrow) {
    float: left;
    padding-top: 3px;
    min-width: 25px;
    padding-right: 5px;
}

#eurid #container #aside-container {
    z-index: 3;
}

#eurid #mainnav-container{
    z-index: 3
}
#eurid .modal-dialog {
    margin-top: 50px;
    margin-bottom: 80px;
}

/* REGISTRAR WEB BLUE MENU */

#eurid.registrarweb #mainnav {
    background-color: #262F44;
}

#eurid.registrarweb #mainnav li a {
    color: #c2c2c2;
}

#eurid.registrarweb #mainnav li a:active,
#eurid.registrarweb #mainnav li a:focus,
#eurid.registrarweb #mainnav li a:hover {
    color: #fff;
}

#eurid.registrarweb  #mainnav-menu > .active .active,
#eurid.registrarweb  #mainnav-menu > .active {
    background-color: #2a3b64;
}

#eurid div.div-menu-title {
    padding-left: 25px;
}

.toggle-box-label-left:empty{
  margin-left: -10px;
}

.toggle-box-label-left:before, .toggle-box-label-left:after{
    box-sizing:border-box;
    margin:0;
    padding:0;
    /*transition*/
    -webkit-transition:.25s ease-in-out;
    -moz-transition:.25s ease-in-out;
    -o-transition:.25s ease-in-out;
    transition:.25s ease-in-out;
    outline:none;
}


.toggle-box input[type=checkbox], .toggle-box input[type=checkbox]:active{
    position:absolute;
    top:-5000px;
    height:0;
    width:0;
    opacity:0;
    border:none;
    outline:none;
}
.toggle-box label{
    display:inline-block;
    position:relative;
    padding:4px;
    margin-bottom:20px;
    font-size:14px;
    line-height:16px;
    cursor:pointer;
    color:  rgba(149, 149, 149, 0.51);
    font-weight: normal;
}
 .toggle-box-label-left:before{
     content:'';
     display:block;
     position:absolute;
     z-index:1;
     line-height:34px;
     text-indent:40px;
     height:16px;
     width:16px;
     margin: 4px;
     /*border-radius*/
     -webkit-border-radius:100%;
     -moz-border-radius:100%;
     border-radius:100%;
     right: 30px;
     bottom: 4px;
     background: #FFB200;
     transform: rotate(-45deg);
     box-shadow: 0 0 10px white;
}
.toggle-box-label-left:after{
    content: "";
    display: inline-block;
    width: 40px;
    height: 24px;
    /*border-radius*/
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.15);
    vertical-align: middle;
    margin: 0 10px;
    border: 2px solid #FFB200;;
}
.toggle-box input[type=checkbox]:checked + .toggle-box-label-left:before{
    right: 20px;
    box-shadow: 5px 5px 0 0 #eee;
    background: transparent;
}
.toggle-box input[type=checkbox]:checked + .toggle-box-label-left:after{
    background: rgba(0, 0, 0, 0.15);
    border: 2px solid white;
}
.toggle-box input[type=checkbox] + .toggle-box-label-left{
    color: rgba(250, 250, 250, 0.51);
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 12px;
}
.toggle-box input[type=checkbox]:checked + .toggle-box-label-left{
    color: rgba(149, 149, 149, 0.51);
    font-weight: normal;
}
.toggle-box input[type=checkbox]:checked + .toggle-box-label-left + .toggle-box-label{
    color: rgba(250, 250, 250, 0.51);
    font-weight: bold;
}

#eurid:not(.night) .night-visible {
    display: none! important;
}

#eurid.night .night-hidden {
    display: none! important;
}

#eurid.night #container #aside .list-link li a:not(.btn) {
  color: #abb1b7;
}
#eurid.night #container #aside .list-link li a:not(.btn):hover {
  color: #abb1b7;
}
#eurid.night #container #aside .badge-stat {
  color: #abb1b7;
}
#eurid.night #container #aside .text-main {
  color: #abb1b7;
}
#eurid.night #aside {
  background-color: #3d4553;
  color: #abb1b7;
    border-left: none;
}
#eurid.night #aside .btn-link {
  color: #abb1b7;
}
#eurid.night #aside .text-muted {
  color: #879da6;
}

#eurid.night .badge-primary,
#eurid.night .btn-primary,
#eurid.night .label-primary {
  background-color: #1976d2 !important;
}

#eurid.night .fade-out-text:before{
    background: linear-gradient(transparent 100px, rgb(171,171,171));
}

#eurid.night .daterangepicker .ranges .input-mini {
    background-color: #414a59;
    color: #ababab;
}

#eurid.night .daterangepicker .daterangepicker_end_input label,
#eurid.night .daterangepicker .daterangepicker_start_input label{
    text-shadow: none;
    color: #ababab;
}

#eurid.night .hourselect,
#eurid.night .minuteselect {
    background-color: #414a59;
    color: #ababab;
}

#eurid.night .ui-autocomplete-category {
    color: #d4d4d4;
}

.night #permission-table .text-danger{
    opacity: 1;
}

.table-super-condensed > tbody > tr > td,
.table-super-condensed > tbody > tr > th,
.table-super-condensed > tfoot > tr > td,
.table-super-condensed > tfoot > tr > th,
.table-super-condensed > thead > tr > td,
.table-super-condensed > thead > tr > th
{
    padding: 2px;
}

#eurid .magic-checkbox + label > span {
    display: inline-block;
    text-indent: 0;
    width: 95%;
}

#eurid #mainnav-container {
    z-index: auto;
}

#eurid .panel-group.accordion .panel-heading > a {
    display: block;
    padding-left: 15px;
}

#eurid .modal-iframe #page-content {
    margin-bottom: 20px;
}

#eurid.night .table .table {
    background-color: transparent;
}

#eurid.night .table>tbody>tr.active>td,
#eurid.night .table>tbody>tr.active>th,
#eurid.night .table>tbody>tr>td.active,
#eurid.night .table>tbody>tr>th.active,
#eurid.night .table>tfoot>tr.active>td,
#eurid.night .table>tfoot>tr.active>th,
#eurid.night .table>tfoot>tr>td.active,
#eurid.night .table>tfoot>tr>th.active,
#eurid.night .table>thead>tr.active>td,
#eurid.night .table>thead>tr.active>th,
#eurid.night .table>thead>tr>td.active,
#eurid.night .table>thead>tr>th.active {
    background-color: transparent;
}

#eurid.night .alert .alert-title {
    color: #fff;
}

#eurid.night .table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #343b47;
}

.dot-eu-logos-crossfade {
    height: 100px;
    width: 150px;
    display: inline-block;
    position: relative;
}

.dot-eu-logos-crossfade .dot-eu-logo {
    animation: logo-switcher 30s infinite;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
}

.dot-eu-logos-crossfade .dot-eu-logo:nth-of-type(1) {
    animation-delay: 0s;
    background-image: url("../img/dot-eu-la-white.png");
    width: 100%;
    height: 100%;
    opacity: 1;
}

.dot-eu-logos-crossfade .dot-eu-logo:nth-of-type(2) {
    animation-delay: 10s;
    background-image: url("../img/dot-eu-el-white.png");
    opacity: 0
}

.dot-eu-logos-crossfade .dot-eu-logo:nth-of-type(3) {
    animation-delay: 20s;
    background-image: url("../img/dot-eu-bg-white.png");
    opacity: 0
}

@keyframes logo-switcher {
  0%, 100%, 33.333% {
    opacity: 0
  }
  2.0%, 31.333% {
    opacity: 1
  }
}

.modal-title {
    max-width: 95%;
    word-wrap: break-word;
}